.modal__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal__card {
    position: relative;
    width: 300px;
    padding: 40px;
}

.modal__close-button {
    position: absolute !important;
    top: 0;
    right: 0;
}

.modal__loading {
    display: flex;
    justify-content: center;
}

.modal__result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
