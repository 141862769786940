.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    /*max-width: 70vw;*/
    gap: 15px;
}
.testStation-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.refreshButton {
    border: 1px solid black;
    padding: 8px;
    background-color: none;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
}
.refreshButton:hover {
    background-color: #80cc9f;
}
.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
#station {
    background: #eee;
    padding: 16px;
    border-radius: 10px;
    display: grid;
    /*grid-template-columns: repeat(3, 1fr);*/
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 10px;
}
.params-select {
    display: grid;
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}
.params {
    display: flex;
    width: 100%;
    flex-flow: row;
    /*justify-content: space-between;*/
    /* padding: 10px; */
    gap: 5px;
    padding: 10px 10px 5px 10px;
    flex-direction: column;
}
@media screen and (max-width: 850px) {
    .params {
        padding: 5px;
    }
    .params-button button {
        width: 24px !important;
        height: 24px !important;
    }
}

.params-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-around;
    position: relative;
}

.row {
    display: flex;
    /* justify-content: space-between; */
    padding-bottom: 5px;
}

.bc {
    margin-left: 4px;
}

.dno {
    /* padding-left: 15px; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    overflow-wrap: anywhere;
}

.button {
    height: 25px;
    width: 25px;
    background: #d5e5db;
    /* margin: 12px; */
    border-radius: 18px;
    cursor: pointer;
    /*box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);*/
    border: none;
    padding: 0;
}

.powerbank {
    display: flex;
    /* justify-content: space-between; */
    /*min-width: 25%;*/
    /* height: 50px; */
    min-height: 70px;
    /*margin: max(1.5%, 5px);*/
    border-radius: 10px;
    vertical-align: top;
    flex-basis: 100%;
    max-width: 400px;
}

.no_bank {
    background: #aaa;
}

.bank {
    background: #80cc9f;
}

.row_item {
    margin-right: 10px;
}

.blocked {
    background: #969696;
    padding: 1px 8px;
    border-radius: 6px;
}

.active {
    background: #1eb000;
    padding: 1px 8px;
    border-radius: 6px;
}

.not_confirmed {
    background: #df0000;
    padding: 1px 8px;
    border-radius: 6px;
    color: #fff;
}

.operational {
    background: #00b6b6;
    padding: 1px 8px;
    border-radius: 6px;
}

.station_switcher {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.logs {
    background-color: #1e1e1e;
    color: #ccccc1;
    height: 500px;
    overflow-y: scroll;
    padding: 15px;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
}

.log {
    padding-bottom: 5px;
}

.log span {
    margin-right: 5px;
}

.log .log__metadata pre {
    margin: 0;
}

.log .log__timestamp {
    display: inline-block;
    width: 130px;
}

.log .log__level {
    display: inline-block;
    width: 50px;
    font-weight: bold;
}

.log--info .log__level {
    color: green;
}

.log--debug .log__level {
    color: blue;
}

.log--error .log__level {
    color: red;
}

@media screen and (max-width: 850px) {
    body {
        font-size: 10px;
    }

    .button {
        height: 15px;
        width: 15px;
        float: right;
        /*margin: 7px;*/
        border-radius: 18px;
    }
}
.station-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.station-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.station-control-label {
    font-size: 1rem;
}
.station-row {
    display: flex;
    justify-content: space-evenly;
    gap: max(1.5%, 5px);
}
